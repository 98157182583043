@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap");

body {
  font-family: "serif";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #000000;
}

.header {
  padding: 20px;
  width: 100%;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  color: #ffffff;
  font-family: serif;
}

.header span {
  font-size: 20px;
  font-weight: bold;
}

.header ul {
  display: flex;
  gap: 20px;
  float: right;
  margin-right: 10%;
}

.header ul a {
  text-decoration: none;
  padding: 5px 10px;
  color: #ffffff;
}

.component {
  font-size: 30px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  background: rgb(214, 223, 135);
}

.about {
  background: rgb(115, 139, 243);
}

.contact {
  background: palevioletred;
}